/** @format */

import { ref, watch, computed, reactive } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axiosIns from '@/libs/axios'

export default function useInvoicesList(proxy) {
  // Use toast
  const toast = useToast()

  const orderListTable = ref(null)

  const isShow = ref(true)
  const SSS = ref(true)

  // Table Handlers
  const tableColumns = ref([])

  const TableClumnFalse = [
    

    {
      key: 'id',
      label: 'Agent',
      thStyle: {
        whiteSpace: 'nowrap',
      },
    },

    {
      label: 'Splits',
      key: 'share_number',
      thStyle: {
        whiteSpace: 'nowrap',
      },
    },

    {
      label: 'Total TP',
      key: 'order_tp',
      thStyle: {
        whiteSpace: 'nowrap',
      },
      formatter: value => `${String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
    },

    {
      label: 'Payment Time',
      key: 'pay_time',
      // 隱藏當前列
      thStyle: {
        whiteSpace: 'nowrap',
      },
    },
    {
      key: 'status',
      label: 'Status',
      thStyle: {
        whiteSpace: 'nowrap',
      },
    },
  ]
  const TableClumnTrue = [
    {
      key: 'status',
      label: 'Status',
      thStyle: {
        whiteSpace: 'nowrap',
      },
    },
    {
    key: 'actions',
    label: 'Details',
    thStyle: { width: '20px', textAlign: 'center', whiteSpace: 'nowrap' },
  },
  {
    key: 'id',
    label: 'Agent',
    thStyle: {
      whiteSpace: 'nowrap',
    },
  },
  {
    label: 'Policy No.',
    key: 'number',
    thStyle: {
      whiteSpace: 'nowrap',
    },
  },
  {
    label: 'Product',
    key: 'product.title',
    thStyle: {
      whiteSpace: 'nowrap',
    },
  },
  {
    label: 'Splits',
    key: 'share_number',
    thStyle: {
      whiteSpace: 'nowrap',
    },
  },
  
  // {
  //   label: 'Type',
  //   key: 'count_cate',
  //   formatter: value => (!value ? '-- 无 --' : `${value}`),
  //   thStyle: {
  //     whiteSpace: 'nowrap',

  //   },
  // },
  {
    label: 'Total TP',
    key: 'order_tp',
    thStyle: {
      whiteSpace: 'nowrap',
    },
    formatter: value => `${String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`,
  },
  {
    key: 'all_money',
    label: 'Paid Commission',
    thStyle: {
      whiteSpace: 'nowrap',
    },
    formatter: value => (value ? `$${String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}` : value),
  },
  {
    label: 'Payment Time',
    key: 'pay_time',
    // 隱藏當前列
    thStyle: {
      whiteSpace: 'nowrap',
    },
  }]

  const perPage = ref(100) // pageSize
  const totalInvoices = ref(0) // total
  const currentPage = ref(1) // now Page
  const perPageOptions = [100,200,500,1000]  // pageSize Array
  const status = ref('') // pageSize
  const statusOptions = ['Active','Complete'] // pageSize Array
  const searchQuery = ref('') // searchQuery

  const startTime = ref('')
  const endTime = ref('')
  const user_id = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = orderListTable.value
      ? orderListTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalInvoices.value,
    }
  })

  const refetchData = () => {
    orderListTable.value.refresh()
  }

  watch([currentPage, perPage, status, searchQuery], () => {
    refetchData()
  })

  const fetchInvoices = (ctx, callback) => {
    axiosIns
      .get('/order/comission', { params: {
        perPage: perPage.value,
        page: currentPage.value,
        number: searchQuery.value,
        // user_id: user_id.value,
        startTime: startTime.value,
        endTime: endTime.value,
        status:status.value,
      } })
      .then(response => {
        const { list, total, is_show } = response.data
        if (is_show) {
          tableColumns.value = TableClumnTrue
        } else {
          tableColumns.value = TableClumnTrue
        }
        proxy.$forceUpdate()

        callback(list)
        totalInvoices.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching invoices' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })

    // store
    //   .dispatch('user-orders/fetchListData', {
    //     perPage: perPage.value,
    //     page: currentPage.value,
    //     number: searchQuery.value,
    //     // user_id: user_id.value,
    //     startTime: startTime.value,
    //     endTime: endTime.value,
    //   })
    //   .then(response => {
    //     const { list, total } = response.data
    //     callback(list)
    //     totalInvoices.value = total
    //   })
    //   .catch(() => {
    //     toast({
    //       component: ToastificationContent,
    //       props: {
    //         title: "Error fetching invoices' list",
    //         icon: 'AlertTriangleIcon',
    //         variant: 'danger',
    //       },
    //     })
    //   })
  }

  // 删除
  const deleteInvoice = val => {
    store
      .dispatch('user-order/orderDelete', val)
      .then(response => {
        if (response.code == 0) {
          toast({
            component: ToastificationContent,
            props: {
              title: response.msg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          refetchData()
        } else {
          toast({
            component: ToastificationContent,
            props: {
              title: response.msg,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: '删除错误!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  // 审核
  const auditInvoice = val => {
    store
      .dispatch('user-order/orderAudit', val)
      .then(response => {
        if (response.code == 0) {
          toast({
            component: ToastificationContent,
            props: {
              title: response.msg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          proxy.$bvModal.hide('audit-modal')
          refetchData()
        } else {
          toast({
            component: ToastificationContent,
            props: {
              title: response.msg,
              icon: 'CheckCircleIcon',
              variant: 'danger',
            },
          })
        }
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: '审核错误!',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  // 订单详情

  return {
    fetchInvoices,
    tableColumns,
    perPage,
    status,
    currentPage,
    totalInvoices,
    dataMeta,
    perPageOptions,
    statusOptions,
    searchQuery,
    orderListTable,
    deleteInvoice,
    auditInvoice,
    refetchData,
    startTime,
    endTime,
    SSS
  }
}
