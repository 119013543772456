<!-- @format -->

<template>
  <b-card no-body>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col md="12" sm="12" xl="4" class="d-flex align-items-center mb-1 mb-md-0 export-row">
          <p style="margin: 0">
            <label>Pages</label>
            <v-select v-model="perPage" :options="perPageOptions" :clearable="false" class="per-page-selector d-inline-block ml-50 mr-1" />
          </p>
          <!--          <b-button variant="primary" :to="{ name: 'policy_list_create' }"> Add </b-button>-->
          <!--     <b-button variant="primary" @click="exportXlsx" :disabled="isLoading" class="ml-2"> Export </b-button> -->

          <!--          <div class="wocc">
            <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret class="p-0">
              <template v-slot:button-content class="p-0" style="padding: 0">
                <b-button variant="primary" class="ml-1" v-if="piliangArray.length > 0"> Batch operation </b-button>
              </template>
              <b-dropdown-item @click="deleteInvoicePl">
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span>Batch delete</span>
              </b-dropdown-item>
              <b-dropdown-item @click.prevent.stop=";[$bvModal.show('audit-modal'), (checkForm.id = piliangArray)]">
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span>Batch Audit</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>-->
        </b-col>

        <!-- Search -->
        <b-col cols="14" md="12" sm="12" xl="8" class="from-row">
          <v-select v-model="status" :options="statusOptions" :clearable="true" placeholder="Select Status" style="margin-right:5px;width:700px;"/>
          <b-form-input v-model="searchQuery" class="d-inline-block mr-1 from-row-item" placeholder="Policy Number/Agent ID/Agent Name" />
          <flat-pickr v-model="startTime" class="form-control" placeholder="Start Time" style="margin-right: 1rem;" />
          <flat-pickr v-model="endTime" class="form-control" placeholder="End Time" />
        </b-col>
      </b-row>
    </div>

    <b-table
      bordered
      tbody-tr-class="text-center"
      :selectable="selectable"
      select-mode="multi"
      @row-clicked="contentChange"
      ref="orderListTable"
      :items="fetchInvoices"
      responsive
      :fields="tableColumns"
      primary-key="id"
      show-empty
      empty-text="No matching records found"
      class="position-relative text-center"
      v-if="SSS"
    >
      <template #cell(selected)="{ rowSelected }">
        <template v-if="rowSelected">
          <span aria-hidden="true">&check;</span>
          <span class="sr-only">Selected</span>
        </template>
        <template v-else>
          <span aria-hidden="true">&#x2610;</span>
          <span class="sr-only">Not Selected</span>
        </template>
      </template>

      <template #head(invoiceStatus)>
        <feather-icon icon="TrendingUpIcon" class="mx-auto" />
      </template>

      <!-- Column: Id -->
      <template #cell(id)="data">
        <div @click="setItemDetail(data)">
          <div class="font-medium-1">{{ data.item.create_name }}</div>
          <div>{{ data.item.create_level }}</div>
          <div class="grid-hero" style="color: #ccc">
            {{ data.item.create_id }}
          </div>
        </div>

      </template>
      <template #cell(product)="data">
        <div class="font-medium-1">
          {{ data.item.product.type.type_id == 1 ? 'LIFE' : 'ANNUITY' }}
        </div>
        <div class="grid-hero" style="color: #ccc">
          {{ data.item.product.title }}
        </div>
      </template>

      <template #cell(number)="data">
        <div class="font-medium-1">{{ data.item.number }}</div>
        <div class="grid-hero" style="color: #ccc">{{ data.item.product.type.type_id == 1 ? 'LIFE' : 'ANNUITY' }}: {{ data.item.money }} $</div>
      </template>

      <!-- Column: Invoice Status -->
      <template #cell(status)="data">
        <b-badge :variant="data.item.status | filterStatusColor" class="mx-auto">{{ data.item.status | filterStatusTxt }}</b-badge>
      </template>

      <!-- Column: Issued Date -->
      <template #cell(issuedDate)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>

      <template #cell(share_number)="data">
        <b-link>
          #<span class="text-nowrap" @click="getMoneyInfo(data.item, data.item.id)"> {{ data.item.share_rate.length }}People </span>
        </b-link>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <b-link @click="setItemDetail(data)">
          <feather-icon icon="EyeIcon" size="21" />
        </b-link>
      </template>
    </b-table>

    <b-modal id="audit-modal" cancel-variant="outline-secondary" ok-title="Confirm" cancel-title="Cancel" centered title="Review Policy">
      <validation-observer ref="auditForm">
        <b-form>
          <b-form-group label="Policy TP:">
            <validation-provider #default="{ errors }" name="Policy TP" rules="required">
              <b-form-input v-model="checkForm.order_tp" type="email" placeholder="Policy TP" :state="errors.length > 0 ? false : null" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!--          <b-form-group>-->
          <!--            <label>Policy Status</label>-->
          <!--            <validation-provider #default="{ errors }" name="Review Status" rules="required">-->
          <!--              <v-select-->
          <!--                v-model="checkForm.status"-->
          <!--                :reduce="item => item.value"-->
          <!--                :placeholder="'Status'"-->
          <!--                :state="errors.length > 0 ? false : null"-->
          <!--                :class="[errors.length > 0 ? 'style-chooser' : 'style-chooser-none']"-->
          <!--                :options="[-->
          <!--                  { label: 'Submitted', value: 1 },-->
          <!--                  { label: 'Reviewed', value: 2 },-->
          <!--                  { label: 'Active', value: 3 },-->
          <!--                ]" />-->
          <!--              <small class="text-danger">{{ errors[0] }}</small>-->
          <!--            </validation-provider>-->
          <!--          </b-form-group>-->
          <!--        改为单选按钮  -->
          <b-form-group label="Policy Status:">
            <!--            <label>Policy Status</label>-->
            <validation-provider #default="{ errors }" name="Review Status" rules="required">
              <b-form-radio-group v-model="checkForm.status" :state="errors.length > 0 ? false : null">
                <!--                <b-form-radio value="1">Submitted</b-form-radio>-->
                <b-form-radio value="2">Reviewed</b-form-radio>
                <b-form-radio value="3">Active</b-form-radio>
              </b-form-radio-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group label="Commission Status:">
            <!--            <label>Policy Status</label>-->
            <validation-provider #default="{ errors }" name="Commission Status" rules="required">
              <b-form-radio-group v-model="checkForm.count_cate" :state="errors.length > 0 ? false : null">
                <b-form-radio value="1">First Year</b-form-radio>
                <b-form-radio value="2">Renew</b-form-radio>
                <b-form-radio value="3">Excess</b-form-radio>
              </b-form-radio-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
      <!--   footer   -->
      <template #modal-footer>
        <b-button variant="secondary" @click.prevent="$bvModal.hide('audit-modal')">Cancel</b-button>
        <b-button variant="primary" @click.prevent="auditInvoice">Confirm</b-button>
      </template>
    </b-modal>

    <b-modal id="money-modal" cancel-variant="outline-secondary" ok-title="Confirm" cancel-title="Cancel" centered title="Split info" size="lg">
      <b-table :items="fenqianField" responsive :fields="fenqianFieldColumn" primary-key="id" show-empty empty-text="No matching records found" class="position-relative" />
      <!--   footer   -->
      <template #modal-footer>
        <b-button variant="secondary" @click.prevent="$bvModal.hide('money-modal')">Close</b-button>
      </template>
    </b-modal>

    <b-modal id="delete-modal" cancel-variant="outline-secondary" ok-title="Confirm" cancel-title="Cancel" centered title="ConfirmDelete" size="sm">
      Whether to confirm the deletion of the selected policy？
      <template #modal-footer>
        <b-button variant="danger" @click.prevent="confirmPLDelete">Confirm</b-button>
        <b-button variant="secondary" @click.prevent="$bvModal.hide('delete-modal')">Close</b-button>
      </template>
    </b-modal>

    <div class="mx-2 mb-2">
      <b-row>
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
          <b-pagination v-model="currentPage" :total-rows="totalInvoices" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BFormInput, BButton, BTable, BLink, BBadge, BPagination, BFormRadio, BFormRadioGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { reactive, getCurrentInstance, nextTick, ref } from '@vue/composition-api'
import { required } from '@core/utils/validations/validations'
import axiosIns from '@/libs/axios'
import flatPickr from 'vue-flatpickr-component'
import useInvoicesList from './useOrderList'

import orderModule from './OrderManagerList'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BPagination,
    vSelect,
    BFormRadio,
    BFormRadioGroup,
    flatPickr
  },
  filters: {
    filterStatusTxt(value) {
      switch (value) {
        case 1:
          return 'Submitted'
        case 2:
          return 'Reviewed'
        case 3:
          return 'Active'
        case 4:
          return 'Completed'
        default:
          return 'Completed'
      }
    },
    filterStatusColor(value) {
      switch (value) {
        case 1:
          return 'primary'
        case 2:
          return 'success'
        case 3:
          return 'success'
        case 4:
          return 'success'
        default:
          return 'primary'
      }
    },
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'user-orders'
    const { proxy } = getCurrentInstance()
    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, orderModule)

    const {
      SSS,
      startTime,
      endTime,
      auditInvoice: auditInvoiceStore,
      fetchInvoices,
      tableColumns,
      perPage,
      status,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      statusOptions,
      searchQuery,
      orderListTable,
      deleteInvoice,
      refetchData,
    } = useInvoicesList(proxy)
    const dateQuery = ref('')
    const checkForm = reactive({
      order_tp: '',
      status: '',
      id: '',
      count_cate: '1',
    })

    const auditInvoice = () => {
      proxy.$refs.auditForm.validate().then(isOk => {
        if (isOk) {
          if (Array.isArray(checkForm.id)) {
            proxy.$bvToast.toast('功能暂未开放')
            // axiosIns
            //   .put('/order/apply-all', {
            //     ids: checkForm.id,
            //     order_tp: checkForm.order_tp,
            //     status: checkForm.status,
            //     count_cate: checkForm.count_cate,
            //   })
            //   .then(res => {
            //     if (res.data.code === 0) {
            //       refetchData()
            //       proxy.$bvModal.hide('audit-modal')
            //     }
            //   })
          } else {
            auditInvoiceStore(checkForm)
          }
        } else {
          // console.log('error')
        }
      })
    }
    const fenqianField = reactive([])
    const fenqianFieldColumn = [
      {
        key: 'user_id',
        label: 'Memberid',
      },
      {
        key: 'real_name',
        label: 'Member',
      },
      {
        key: 'level',
        label: 'MemberLevel',
      },
      {
        key: 'share_rate',
        label: 'Split Percent',
        formatter: (value, row) => `${value}%`,
      },
      {
        key: 'tp',
        label: 'Split TP',
        formatter: (value, row) => `${value}`,
      },
      {
        key: 'money',
        label: '分担 Amount',
      },
      {
        label: '状态',
        key: 'stu_name',
      },
    ]

    const lock = ref(false)
    const getMoneyInfo = (item, id) => {
      document.body.style.cursor = 'wait'
      if (lock.value) return
      lock.value = true
      setTimeout(() => {
        axiosIns
          .get('/order/getRateInfo', { params: { order_id: id } })
          .then(response => {
            if (response.code == 0) {
              proxy.$bvModal.show('money-modal')
              nextTick(() => {
                fenqianField.splice(0, fenqianField.length, ...response.data)
              })
              lock.value = false
              document.body.style.cursor = 'default'
            } else {
              document.body.style.cursor = 'default'
              lock.value = false
              proxy.$bvToast.toast(response.msg, {
                title: 'Tips',
                variant: 'danger',
                solid: true,
                appendToast: true,
                autoHideDelay: 2000,
              })
            }
          })
          .catch(() => {
            lock.value = false
            document.body.style.cursor = 'default'
            proxy.$bvToast.toast('网络错误', {
              title: 'Tips',
              variant: 'danger',
              solid: true,
              appendToast: true,
              autoHideDelay: 2000,
            })
          })
      }, 1000)
    }

    const setItemEdit = data => {
      store.commit('user-order/setOrderDetail', data.item)
      setTimeout(() => {
        proxy.$router.push({
          name: 'policy_list_edit',
        })
      }, 500)
    }

    const setItemDetail = data => {
      // store.commit('user-order/setOrderDetail', data.item)
      setTimeout(() => {
        proxy.$router.push({
          name: 'vip_Commission_record_detail',
          query: {
            id: data.item.id,
          },
        })
      }, 500)
    }

    const setItemEditCreate = data => {
      store.commit('user-order/setOrderDetail', data.item)
      setTimeout(() => {
        proxy.$router.push({
          name: 'policy_list_add_money',
        })
      }, 500)
    }

    const selectable = ref(true)

    const piliangForm = reactive({
      id: '',
    })
    const piliangArray = ref([])
    const contentChange = (item, index, event) => {
      if (piliangArray.value.indexOf(item.id) == -1) {
        piliangArray.value.push(item.id)
      } else {
        piliangArray.value.splice(piliangArray.value.indexOf(item.id), 1)
      }
    }

    const deleteInvoicePl = () => {
      if (piliangArray.value.length == 0) {
        proxy.$bvToast.toast('Select the policy to be deleted', {
          title: 'Tips',
          variant: 'danger',
          solid: true,
          appendToast: true,
          autoHideDelay: 2000,
        })
      } else {
        // 弹出是否DeleteTips框
        proxy.$bvModal.show('delete-modal')
      }
    }
    const confirmPLDelete = () => {
      axiosIns.delete('/order/delete-all', { params: { ids: piliangArray.value } }).then(response => {
        if (response.code == 0) {
          proxy.$bvModal.hide('delete-modal')
          proxy.$bvToast.toast('Deleted successfully', {
            title: 'Tips',
            variant: 'success',
            solid: true,
            appendToast: true,
            autoHideDelay: 2000,
          })
          refetchData()
        } else {
          proxy.$bvModal.hide('delete-modal')
          proxy.$bvToast.toast(response.msg, {
            title: 'Tips',
            variant: 'danger',
            solid: true,
            appendToast: true,
            autoHideDelay: 2000,
          })
        }
      })
    }
    const isLoading = ref(false)
    const exportXlsx = () => {
      if (isLoading.value) return
      isLoading.value = true
      proxy.$bvToast.toast('Exporting...', {
        title: 'Tips',
        variant: 'primary',
        solid: true,
        appendToast: true,
        autoHideDelay: 2000,
      })
      // 原始 http 请求
      // const httpRequest = new XMLHttpRequest()
      // httpRequest.open('get', 'http://127.0.0.1:8000/backend/order/export')
      // // 设置请求头
      // httpRequest.setRequestHeader('token', localStorage.getItem('accessToken'))
      // httpRequest.responseType = 'blob'
      //
      // httpRequest.send()
      // 判断请求是否成功
      // httpRequest.onreadystatechange = function () {
      //   if (httpRequest.readyState === 4 && httpRequest.status === 200) {
      //     isLoading.value = false
      //     // 获取到文件的二进制数据
      //     const blob = httpRequest.response
      //     // 创建一个blob对象
      //     const blobObject = new Blob([blob], { type: 'application/vnd.ms-excel' })
      //     // 创建一个a标签
      //     const a = document.createElement('a')
      //     // 创建一个url对象
      //     const url = URL.createObjectURL(blobObject)
      //     // 将a标签的href属性设置为url对象
      //     a.href = url
      //     // 设置a标签的download属性
      //     a.download = '订单列表.xlsx'
      //     // 触发a标签的点击事件
      //     a.click()
      //     // 取消url对象
      //     URL.revokeObjectURL(url)
      //   } else {
      //     proxy.$bvToast.toast('导出失败', {
      //       title: 'Tips',
      //       variant: 'danger',
      //       solid: true,
      //       appendToast: true,
      //       autoHideDelay: 2000,
      //     })
      //   }
      // }
      // 改进为 axios 请求
      axiosIns(`order/export?pageSize=${perPage}&page=${currentPage}`, {
        responseType: 'blob',
        // baseURL: 'http://127.0.0.1:8000/backend/',
      })
        .then(res => {
          isLoading.value = false
          proxy.$bvToast.toast('Export successful', {
            title: 'Tips',
            variant: 'success',
            solid: true,
            appendToast: true,
            autoHideDelay: 0,
          })
          // console.log(res)
          const blob = res
          const blobObject = new Blob([blob], { type: 'application/vnd.ms-excel' })
          // 创建一个a标签
          const a = document.createElement('a')
          // 创建一个url对象
          const url = URL.createObjectURL(blobObject)
          // 将a标签的href属性设置为url对象
          a.href = url
          // 设置a标签的download属性
          a.download = 'Commission-Report.xlsx'
          // 触发a标签的点击事件
          a.click()
          // 取消url对象
          URL.revokeObjectURL(url)
        })
        .catch(() => {
          isLoading.value = false
          proxy.$bvToast.toast('Export failure', {
            title: 'Tips',
            variant: 'danger',
            solid: true,
            appendToast: true,
            autoHideDelay: 2000,
          })
        })

      // httpRequest.onload = () => {
      //   const blob = new Blob([httpRequest.response])
      //   FileSaver.saveAs(blob, 'order.xlsx')
      // }
    }

    return {
      isLoading,
      exportXlsx,
      startTime,
      endTime,
      setItemEditCreate,
      confirmPLDelete,
      deleteInvoicePl,
      piliangArray,
      piliangForm,
      contentChange,
      setItemDetail,
      selectable,
      setItemEdit,
      fenqianField,
      fetchInvoices,
      tableColumns,
      perPage,
      status,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      statusOptions,
      searchQuery,
      orderListTable,

      refetchData,
      deleteInvoice,
      auditInvoice,
      checkForm,
      required,
      getMoneyInfo,
      fenqianFieldColumn,
      dateQuery,
      SSS
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 100px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
.wocc {
  >>> .dropdown-toggle {
    padding: 0px !important;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
.export-row {
  justify-content: flex-start;
}
.from-row {
  display: flex;
  flex-direction: row;
}

@media (min-width: 768px) and (max-width: 1200px) {
  .from-row {
    margin-top: 1rem;
  }
  .from-row-item {
    margin-bottom: 1rem;
  }
}
@media (max-width: 768px) {
  .export-row {
    justify-content: space-between;
  }
  .from-row {
    flex-direction: column;
  }
  .from-row-item {
    margin-bottom: 1rem;
  }
}
</style>
